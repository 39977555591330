<template>
  <a-layout>
    <a-layout-header style="background: #fff; padding: 0"/>
    <a-layout-content style="margin: 0 16px">
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>文章列表</a-breadcrumb-item>
      </a-breadcrumb>
      <!--            <div class="toolsbar">-->
      <!--                <div style="float: left;margin-left: 20px;margin-top: 10px;">-->
      <!--                    <a-button type="primary" @click="addadmin()">新建文章</a-button>-->
      <!--                    <a-button type="primary" :loading="loading" @click="start" style="margin-left: 20px">-->
      <!--                        刷新-->
      <!--                    </a-button>-->
      <!--                </div>-->
      <!--                <div class="serach_input">-->
      <!--                    <a-input-group compact>-->
      <!--                        <a-select defaultValue="文章名">-->
      <!--                            <a-select-option value="username">文章名</a-select-option>-->
      <!--                            &lt;!&ndash;                    <a-select-option value="username">文章名</a-select-option>&ndash;&gt;-->
      <!--                        </a-select>-->
      <!--                        <a-input-search style="width: 64%" defaultValue @search="onSearch" enterButton/>-->
      <!--                    </a-input-group>-->
      <!--                </div>-->
      <!--            </div>-->
      <a-row>
        <a-col :push="6" :span="18">
          <div v-show="calendar_show" :style="{ padding: '24px', background: '#fff', minHeight: '650px' }">
            <a-row justify="space-between" type="flex">

              <a-col :span="24" style="margin-bottom:10px">

<!--                {{calendarinfo}}-->
                <div v-for="(item,index) in calendarinfo" :key="index">

                  <a-page-header
                      :title="item.times"
                  >
                    <template slot="extra">
                      <a-button style="margin-right: 10px" type="primary" @click="addcalendar">
                        新建事件
                      </a-button>
                    </template>
                  </a-page-header>
                  <a-result title="No data" v-if="item.info.length === 0">

                  </a-result>
                  <Div class="cardlist" >
                    <a-card v-for="items in item.info" :key="items.id" class="cards" hoverable>
                      <div class="del">
                        <a-button type="primary" size="small" style="margin-right: 10px">
                          编辑
                        </a-button>
                        <a-button type="danger" size="small"  @click="delcalendar(items.id,item.times)">
                          剔除
                        </a-button>
                      </div>
                      <a-tag v-if="items.type2 === 1" :color="'#002654'" class="tags">培训</a-tag>
                      <a-tag v-if="items.type2 === 2" :color="'#CE1126'" class="tags">会议</a-tag>
                      <a-tag v-if="items.type2 === 3" :color="'#f50'" class="tags">节日</a-tag>
                      <a-tag v-if="items.type === 1" :color="'blue'" class="tags">今日</a-tag>
                      <a-tag v-if="items.type === 2" :color="'blue'" class="tags">每日</a-tag>
                      <a-tag v-if="items.type === 3" :color="'blue'" class="tags">每周</a-tag>
                      <a-tag v-if="items.type === 4" :color="'blue'" class="tags">每月</a-tag>
                      <a-tag v-if="items.type === 5" :color="'blue'" class="tags">每年</a-tag>
                      <h1 class="title">{{ items.title.cn }}</h1>
                      <!--                    <div> {{ item.content }}</div>-->
                      <div class="work_mode">
                        <div class="lefttime times">
                          <div>开始时间：</div>
                          <div>{{ items.work_time }}<br/>
                            {{items.content.cn.starttimes| dateFormat('HH:mm')}}</div>
                        </div>
                        <div class="righttime times">
                          <div>
                            <div>结束时间：</div>
                            <div>{{ items.work_time2 }}<br/>
                              {{items.content.cn.endtimes| dateFormat('HH:mm')}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="address">{{ items.content.cn.localaddress }}</div>
                      <div class="button">
                        <a-button shape="round" type="danger">
                          查看详情 <a-icon type="arrow-right" />
                        </a-button>
                      </div>
                    </a-card>
                  </Div>
                </div>

              </a-col>

            </a-row>
<!--            {{ calendarinfo }}-->


          </div>
        </a-col>
        <a-col :pull="18" :span="6">
          <div :style="{ padding: '24px', background: '#fff', minHeight: '650px' }">
            <a-calendar :fullscreen="false" class="calenderdate" @panelChange="onPanelChange" @select="selectday">
            <span slot="dateCellRender" slot-scope="value" class="events">
<!--              {{getType(value)}}-->
              <span v-if="getType(value) !== 'null' ">
                 <a-badge :status="getType(value)"/>
              </span>
            </span>
            </a-calendar>
          </div>
        </a-col>
      </a-row>

    </a-layout-content>
    <a-layout-footer style="text-align: center"></a-layout-footer>
    <a-modal v-model="pop_calendaredit" title="编辑分类" width="45%" @ok="calendar_editok">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="分类名称">
        <a-input v-model="edit_calendar.name"/>
      </a-form-item>
    </a-modal>

    <a-modal v-model="pop_calendaradd" title="新增事件" width="65%" @ok="calendar_addok">
<!--      {{add_calendar}}-->
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="事件标题-Cn">
        <a-input v-model="add_calendar.title.cn"/>
      </a-form-item>
<!--      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="事件标题-En">-->
<!--        <a-input v-model="add_calendar.title.en"/>-->
<!--      </a-form-item>-->
<!--      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="事件标题-Fr">-->
<!--        <a-input v-model="add_calendar.title.fr"/>-->
<!--      </a-form-item>-->
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="举办地点-Cn">
        <a-input v-model="add_calendar.content.cn.localaddress"/>
      </a-form-item>
<!--      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="举办地点-En">-->
<!--        <a-input v-model="add_calendar.content.en.localaddress"/>-->
<!--      </a-form-item>-->
<!--      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="举办地点-Fr">-->
<!--        <a-input v-model="add_calendar.content.fr.localaddress"/>-->
<!--      </a-form-item>-->
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="重复周期">
        <a-radio-group v-model="add_calendar.type" button-style="solid"  @change="Changetype">
          <a-radio-button value="1">
            不重复
          </a-radio-button>
          <a-radio-button value="2">
            每日
          </a-radio-button>
          <a-radio-button value="3">
            每周
          </a-radio-button>
          <a-radio-button value="4">
            每月
          </a-radio-button>
          <a-radio-button value="5">
            每年
          </a-radio-button>
          <a-radio-button value="6">
            按时间范围
          </a-radio-button>
        </a-radio-group>
      </a-form-item>

      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="请选择日期" >
<!--        {{add_calendar.type}}-->
<!--        {{add_calendar.work_time}}-->
<!--        {{starttime}}-->
        <h4 v-if="add_calendar.work_time===''||starttime===''" style="color:#CE1126">未选择指定日期或时间</h4>
        <div v-if="add_calendar.type === '1'">
          <h4 v-if="add_calendar.work_time!==''&&starttime!==''">已选择 {{add_calendar.work_time}}日 {{starttimes}} 到 {{endtimes}}</h4>
          <a-calendar :fullscreen="false"  @select="Changeoperation_1" class="calenderdate" />
          <a-divider />
          <a-time-picker format="h:mm a" @change="onChangestart" style="min-width:160px"  v-model="starttime"/>~
          <a-time-picker format="h:mm a" @change="onChangeend"  style="min-width:160px" v-model="endtime"/>
        </div>
        <div v-if="add_calendar.type === '2'">
          <h4 v-if="starttime!==''">已选择每日 {{starttimes}} 到 {{endtimes}}</h4>
          <a-time-picker format="h:mm a" @change="onChangestart" style="min-width:160px"  v-model="starttime"/>~
          <a-time-picker format="h:mm a" @change="onChangeend"  style="min-width:160px" v-model="endtime"/>
        </div>
        <div v-if="add_calendar.type === '3'">
          <h4 v-if="add_calendar.work_time!==''&&starttime!==''">已选择每周{{add_calendar.work_time}}  {{starttimes}} 到 {{endtimes}}</h4>
        <a-radio-group button-style="solid"  @change="Changeoperation_3" v-model="add_calendar.work_time">
          <a-radio-button value="Sunday">
            SUN
          </a-radio-button>
          <a-radio-button value="Monday">
            MON
          </a-radio-button>
          <a-radio-button value="Tuesday">
            TUE
          </a-radio-button>
          <a-radio-button value="Wednesday">
            WED
          </a-radio-button>
          <a-radio-button value="Thursday">
            THU
          </a-radio-button>
          <a-radio-button value="Friday">
            FRI
          </a-radio-button>
          <a-radio-button value="Saturday">
            SAT
          </a-radio-button>
        </a-radio-group>
          <a-divider />
          <a-time-picker format="h:mm a" @change="onChangestart" style="min-width:160px"  v-model="starttime"/>~
          <a-time-picker format="h:mm a" @change="onChangeend"  style="min-width:160px" v-model="endtime"/>
        </div>
        <div v-if="add_calendar.type === '4'">
          <h4 v-if="add_calendar.work_time!==''&&starttime!==''">已选择每月{{add_calendar.work_time}}日 {{starttimes}} 到 {{endtimes}}</h4>
          <a-calendar :fullscreen="false" @select="Changeoperation_4" class="calenderdate"/>
          <a-divider />
          <a-time-picker format="h:mm a" @change="onChangestart" style="min-width:160px"  v-model="starttime"/>~
          <a-time-picker format="h:mm a" @change="onChangeend"  style="min-width:160px" v-model="endtime"/>
        </div>
        <div v-if="add_calendar.type === '5'">
          <h4 v-if="add_calendar.work_time!==''&&starttime!==''">已选择每年{{add_calendar.work_time}}日 {{starttimes}} 到 {{endtimes}}</h4>
          <a-calendar :fullscreen="false" @select="Changeoperation_5" class="calenderdate"/>
          <a-divider />
          <a-time-picker format="h:mm a" @change="onChangestart" style="min-width:160px"  v-model="starttime"/>~
          <a-time-picker format="h:mm a" @change="onChangeend"  style="min-width:160px" v-model="endtime"/>
        </div>
        <div v-if="add_calendar.type === '6'">
          <a-range-picker
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD HH:mm"
              :placeholder="['Start Time', 'End Time']"
              @change="Changeoperation_6"
              @ok="onOk"
          />
        </div>
      </a-form-item>
<!--      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="性别">-->
<!--        <a-input v-model="add_User.sex"/>-->
<!--      </a-form-item>-->
<!--      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="邮箱">-->
<!--        <a-input v-model="add_User.email"/>-->
<!--      </a-form-item>-->
<!--      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="头像">-->
<!--        <a-input v-model="add_User.photo"/>-->
<!--      </a-form-item>-->
    </a-modal>

  </a-layout>

</template>

<script>
// const treeData = [
//   {
//     title: '分类一',
//     key: '0',
//     children: [
//       {
//         title: '分类一一',
//         key: '00',
//         children: [
//           {title: '分类一一一', key: '001'},
//           {title: '分类一一二', key: '002'},
//           {title: '分类一一三', key: '003'},
//         ],
//       },
//       {
//         title: '分类一二',
//         key: '01',
//         children: [
//           {title: '分类一二一', key: '011'},
//           {title: '分类一二二', key: '012'},
//           {title: '分类一二三', key: '013'},
//         ],
//       },
//     ],
//   },
// ];
import moment from "moment";

const columns = [
  // {
  //   title: "id",
  //   dataIndex: "uid",
  //   width: "5%",
  //   scopedSlots: {customRender: "uid"}
  // },
  {
    title: '日历事件',
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];
const cards = [
  {
    title: "id",
    dataIndex: "id",
    width: "5%",
    scopedSlots: {customRender: "id"}
  },
  {
    title: "标题",
    dataIndex: "title",
    width: "25%",
    scopedSlots: {customRender: "title"}
  },

  {
    title: "描述",
    dataIndex: "content",
    scopedSlots: {customRender: "content"}
  },
  // {
  //   title: "最后登录时间",
  //   dataIndex: "updated_at",
  //   width: "15%",
  //   scopedSlots: {customRender: "updated_at"}
  // },

  {
    title: "状态",
    width: "5%",
    dataIndex: "type",
    scopedSlots: {customRender: "type"}
  },
  {
    title: '操作',
    width: "20%",
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];

import {Modal} from "ant-design-vue";
import Storage from "../common/storage";

// import * as Util from "../common/util";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      columns,
      cards,
      selectedKeys: ['1'],
      expandedKeys: ['1'],
      expandedaddKeys: ['1'],
      data: [],
      calendarData: [],
      calendaruserData: [],
      pagination: {
        pageSize: 10
      },
      calendarinfo: [],
      nowdata: "",
      starttime: "",
      endtime: "",
      starttimes: "",
      endtimes: "",
      calendar_id: "",
      calendar_name: "",
      calendar_show: false,
      loading: false,
      pop_calendaradd: false,
      pop_calendaredit: false,
      add_calendar: {
        type:  "1",
        type2: "",
        title: {
          cn:"",
          en:"",
          fr:"",
        },
        content: {
          cn:{
            localaddress:"",
            starttimes:"",
            endtimes:"",
          },
          en:{
            localaddress:"",
            starttimes:"",
            endtimes:"",
          },
          fr:{
            localaddress:"",
            starttimes:"",
            endtimes:"",
          },
        },
        work_time: "",
        work_time2: "",
      },
      edit_calendar: {
        type: "",
        type2: "",
        title: {
          cn:"",
          en:"",
          fr:"",
        },
        content: {
          cn:"",
          en:"",
          fr:"",
        },
        work_time: "",
        work_time2: "",
      },

    };
  },
  created() {
    // 获取第一页内容
    // console.log("日历Data" ,JSON.parse(sessionStorage.calendarData));
    this.calendarData = JSON.parse(sessionStorage.calendarData)

    let times = new Date;
    let newValue = moment(times).format("YYYY-MM-DD")
    // let mmValue = moment(times).format("YYYY-MM")
    // let year = times.getFullYear();
    // let month = times.getMonth() + 1;
    // this.nowdata = (year.toString() + "-" + month.toString());

    this.selectday(newValue)
    // console.log("日历Data" ,this.calendarData);
  },
  methods: {
    onContextMenuClick(event) {
      console.log('event', event);
      if (event.length !== 0) {
        console.log('event', event[0]);
        this.calendar_id = event[0]
        let treeData = [...this.treeData]
        for (let i in treeData) {
          let info = treeData[i];
          if (info.key === this.calendar_id) {
            this.calendar_name = info.title
          }
        }
        console.log('calendar_id', this.calendar_id);
        console.log('calendar_name', this.calendar_name);
        this.getcalendaruserlist(1, 20, 1)
        this.calendar_show = true;
      } else {
        this.calendar_show = false;

      }
    },
    Changetype(e){
      this.starttime=""
      this.endtime=""
      this.add_calendar.content.cn.starttimes=""
      this.add_calendar.content.en.starttimes=""
      this.add_calendar.content.fr.starttimes=""
      this.add_calendar.content.cn.endtimes=""
      this.add_calendar.content.en.endtimes=""
      this.add_calendar.content.fr.endtimes=""
      this.add_calendar.type = e.target.value
      console.log(this.add_calendar.type);

      if(this.add_calendar.type === "3"){
        this.add_calendar.work_time = "Sunday"
      }else{
        if(this.add_calendar.type === "2"){
          this.add_calendar.work_time = "everyday"
          this.add_calendar.work_time2 = "everyday"
        }else{
        this.add_calendar.work_time = ""
      }
      }

    },
    Changeoperation_1(value){
      this.add_calendar.work_time = moment(value).format("YYYY-MM-DD")
      console.log(this.add_calendar.type);
    },
    Changeoperation_2(){

      console.log(this.add_calendar.type);
    },
    onChangestart(e){
      console.log(moment(e).format("HH:mm"));
      // moment(e).format("MM-DD")
      this.starttime=e
      this.starttimes=moment(e).format("HH:mm")
      this.add_calendar.content.cn.starttimes=this.starttime
      this.add_calendar.content.en.starttimes=this.starttime
      this.add_calendar.content.fr.starttimes=this.starttime

    },
    onChangeend(e){
      console.log(moment(e).format("HH:mm"));
      this.endtime=e
      this.endtimes=moment(e).format("HH:mm")
      this.add_calendar.content.cn.endtimes=this.endtime
      this.add_calendar.content.en.endtimes=this.endtime
      this.add_calendar.content.fr.endtimes=this.endtime
      // moment(e).format("MM-DD")
    },
    Changeoperation_3(e){
      this.add_calendar.work_time = e.target.value
      console.log(this.add_calendar.type);
    },
    Changeoperation_4(value){
      this.add_calendar.work_time =moment(value).format("DD")
      console.log(this.add_calendar.type);
    },
    Changeoperation_5(value){
      this.add_calendar.work_time =moment(value).format("MM-DD")
      console.log(this.add_calendar.type);
    },
    Changeoperation_6(value, dateString){
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);

      this.add_calendar.work_time = dateString[0]
      this.add_calendar.work_time2 = dateString[1]

      this.add_calendar.content.cn.starttimes=this.starttime
      this.add_calendar.content.en.starttimes=this.starttime
      this.add_calendar.content.fr.starttimes=this.starttime
      this.add_calendar.content.cn.endtimes=this.endtime
      this.add_calendar.content.en.endtimes=this.endtime
      this.add_calendar.content.fr.endtimes=this.endtime
      console.log(this.add_calendar.work_time);
      console.log(this.add_calendar.work_time2);
      console.log(this.starttime);
      console.log(this.endtime);
    },
    async onPanelChange(value, mode) {
      console.log("刷新", value);
      console.log("刷新", mode);
      let newValue = moment(value).format("YYYY-MM")
      let newdayValue = moment(value).format("YYYY-MM-DD")
      console.log(newValue);
      if (mode === 'year') {
        this.getcalendarlist(newValue, newdayValue, mode)
      }
    },

    async getcalendarlist(year_month, days, mode) {
      console.log("year_month", year_month);
      console.log("mode", mode);
      this.loading = true;
      let result = await this.$get("/api/admin/task/list", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          year_month: year_month,
        }
      });

      if (result.status === true) {
        // sessionStorage.calendarData = JSON.stringify(result.data);
        sessionStorage.calendarData = [];
        sessionStorage.calendarData = JSON.stringify(result.data);
        this.calendarData = result.data
        // console.log("sessionStorage",sessionStorage.calendarData);
        if (mode === 'year') {
          this.calendarinfo = [];
          console.log("模式", "年");
          for (let i = 0; i < Object.keys(result.data).length; i++) {
            console.log(i, Object.keys(result.data)[i]);
            let info = this.calendarData[Object.keys(result.data)[i]];
            this.calendarinfo[i] = {
              uid: i,
              times: Object.keys(result.data)[i],
              info: info
            }
          }
          console.log("模式", this.calendarinfo);
        }

      }

      this.loading = false;
    },
    getType(value) {
      // console.log("value" ,value);
      let newValue = moment(value).format("YYYY-MM-DD")
      // console.log("value" ,moment(value).format("YYYY-MM-DD"),);
      // console.log("newValue" ,newValue);
      // console.log("newValue" ,this.calendarData[newValue]);
      if (this.calendarData[newValue] !== undefined) {
        if (this.calendarData[newValue].length === 1) {
          return "warning";
        }
        if (this.calendarData[newValue].length === 2) {
          return "success";
        }
        if (this.calendarData[newValue].length >= 3) {
          return "error";
        }


      } else {
        return "null"
      }
    },
    selectday(value, mode) {
      console.log("执行了", mode);
      this.nowdata = value
      let newValue = moment(value).format("YYYY-MM-DD")
      let mmValue = moment(value).format("YYYY-MM")
      console.log("newValue", newValue)
      console.log("mmValue", mmValue)

      console.log(moment(Object.keys(this.calendarData)[0]).format("YYYY-MM"))


      if (mmValue === moment(Object.keys(this.calendarData)[0]).format("YYYY-MM")) {
        console.log("月份一致");
        console.log("当前", this.calendarData)
        this.calendarinfo = [];
        // this.calendarinfo = this.calendarData[newValue]
        this.calendarinfo[0] = {
          uid: 0,
          times: newValue,
          info: this.calendarData[newValue]
        }
        console.log("当前", this.calendarinfo);
        this.calendar_show = true;
      } else {
        console.log("月份不一致");
        Promise.all([this.getcalendarlist(mmValue, newValue, "")]).then(() => {
          console.log("当前", this.calendarData)
          this.calendarinfo = [];
          // this.calendarinfo = this.calendarData[newValue]
          this.calendarinfo[0] = {
            uid: 0,
            times: newValue,
            info: this.calendarData[newValue]
          }
          console.log("当前", this.calendarinfo);
          this.calendar_show = true;
        }).catch((error) => {
          console.log(error)
        })

      }

    },
    start() {
      this.loading = true;
      this.data = [];
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
      this.doSearch(1, this.pagination.pageSize);
    },

    async onSearch(text) {
      this.data = []
      this.search_text = text;
      this.doSearch(1, this.pagination.pageSize, text);
    },

    async onChange(pagination) {
      await this.doSearch(
          this.search_text,
          pagination.current - 1,
          pagination.pageSize
      );
    },

    async onChanges(e) {
      console.log(e.target.value);
    },

    addcalendar() {
      console.log("点击");
      this.add_calendar.type = "1";
      this.add_calendar.type2 = "";
      this.add_calendar.title.cn = "";
      this.add_calendar.title.en = "";
      this.add_calendar.title.fr = "";
      this.add_calendar.work_time = "";
      this.add_calendar.work_time2 = "";
      this.add_calendar.content.cn = {
        localaddress:"",
        starttimes:"",
        endtimes:"",
      };
      this.add_calendar.content.en = {
        localaddress:"",
        starttimes:"",
        endtimes:"",
      };
      this.add_calendar.content.fr = {
        localaddress:"",
        starttimes:"",
        endtimes:"",
      };
      this.pop_calendaradd = true;
    },
    editcalendar() {
      console.log("点击");
      this.edit_calendar.name = this.calendar_name;
      this.pop_calendaredit = true;
    },


    async calendar_addok() {
      if (this.add_calendar.name === "") {
        Modal.warning({
          title: "请输入日历标题"
        });
        this.pop_calendaradd = true;
        return
      }

      // let now = Date.now();
      let result = await this.$post("/api/admin/task/create", {
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          type: this.add_calendar.type,
          type2: this.add_calendar.type2,
          title: this.add_calendar.title,
          content: this.add_calendar.content,
          work_time: this.add_calendar.work_time,
          work_time2: this.add_calendar.work_time2,
        },

      });

      if (result.status === true) {

        let newValue = moment(this.nowdata).format("YYYY-MM-DD")
        let mmValue = moment(this.nowdata).format("YYYY-MM")
        Promise.all([this.getcalendarlist(mmValue, newValue, "")]).then(() => {
          console.log("当前", this.calendarData)
          this.calendarinfo = [];
          // this.calendarinfo = this.calendarData[newValue]
          this.calendarinfo[0] = {
            uid: 0,
            times: newValue,
            info: this.calendarData[newValue]
          }
          console.log("当前", this.calendarinfo);
          this.calendar_show = true;
          // that.calendar_show = false;
        Modal.info({
          title: "更新成功"
        });
      })
      }
      this.pop_calendaradd = false;
    },


    onOk(value) {
      console.log('onOk: ', value);
    },




    async calendar_editok() {
      if (this.edit_calendar.name === "") {
        Modal.warning({
          title: "请输入分类名字"
        });
        return;
      }


      let result = await this.$post("/api/admin/accountGroups/update", {
        uid: Storage.uid,
        token: Storage.token,
        account_group_id: this.calendar_id,
        save_value: {
          name: this.edit_calendar.name,
        },
      });

      if (result.status === true) {
        this.data = []
        await this.getcalendarlist(1, 20, 1);
        Modal.info({
          title: "修改成功"
        });
      }
      this.calendar_name = this.edit_calendar.name;
      this.pop_calendaredit = false;
    },


    async delcalendar(item,times) {
      var that = this;
      console.log(item);
      console.log(times);
      Modal.confirm({
        title: "警告！",
        content: "是否确认剔除该日历事件?",

        onOk: async function () {

          let result = await that.$post("/api/admin/task/delete", {

            uid: Storage.uid,
            token: Storage.token,
              task_id: item,
          });
          console.log(result);
          if (result.status === true) {
            console.log("准备弹窗！");
            let newValue = moment(times).format("YYYY-MM-DD")
            let mmValue = moment(times).format("YYYY-MM")
            Promise.all([that.getcalendarlist(mmValue, newValue, "")]).then(() => {
              console.log("当前", that.calendarData)
              that.calendarinfo = [];
              // this.calendarinfo = this.calendarData[newValue]
              that.calendarinfo[0] = {
                uid: 0,
                times: newValue,
                info: that.calendarData[newValue]
              }
              console.log("当前", that.calendarinfo);
              that.calendar_show = true;
              // that.calendar_show = false;
            Modal.info({
              title: "剔除成功"
            })
          })
          }
          if (result.status === false) {
            // that.data = []
            // await this.doSearch(this.page, "1", "", this.estatedefault, "");
            Modal.info({
              title: result.msg
            });
          }
        }

      });


    },
  }
};
</script>
<style lang="scss" scoped>
.editable-row-operations a {
  margin-right: 8px;
}

.events {
  padding-left: 8px;
}

.cards {
  width: 288px;
  margin-right: 20px;
  border-radius: 8px;
  margin-bottom: 10px;
  .del{
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .tags {
    padding: 2px 8px;
    font-size: 16px;
  }

  .address {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 18px;
    min-height: 96px;
  }

  .work_mode {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;

    .lefttime {
      border-right: 1px solid #000
    }

    .righttime {
      margin-left: 20px;
    }

    .times {
      width: 49%;
      min-height: 50px;
    }

    /deep/ .ant-btn-danger {
      background: #CE1126 !important;
      border: 1px solid #CE1126 !important;
    }
  }
}

.calenderdate {


  /deep/ .ant-radio-group {

    display: none;

  }


}

.cardlist{
  display:flex; justify-content: flex-start;
  flex-flow: wrap;

}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.serach_input {
  width: 25vw;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 25px;
}

.pages {
  float: right;
  margin-top: 10px;
}

.toolsbar {
  display: block;
  width: 100%;
  height: 42px;
  background: #fff
}
</style>
